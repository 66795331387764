/* =======================================================
  Scripts
  ======================================================= */



$(window).on("load", function() {

   // Google analytics
	function googleAnalytics() {
		var AnalyticsScript = document.createElement('script');
		var AnalyticsId = 'GTM-WLFTQRK';
		AnalyticsScript.onload = function () {
			window.dataLayer = window.dataLayer || [];
			function gtag() { dataLayer.push(arguments); }
			gtag('js', new Date());
			gtag('config', AnalyticsId);
		};
	
		AnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + AnalyticsId;
		document.head.appendChild(AnalyticsScript);
	}
	
	if (getCookie('cookieControlTracking') && getCookie('cookieControlTracking') == 'accept') {
		googleAnalytics();
	}
	
	document.addEventListener('cookieAccept', function () {
		googleAnalytics();
	}, false);

   $(".hero__slider").owlCarousel({
      items: 1,
      mouseDrag: false,
      nav: false,
      dots: false,
      autoHeight: true,
      loop: true,
      autoplay: true
   });
   $(".owl-carousel").trigger("refresh.owl.carousel");

   $('.footer__main__awards .owl-carousel').owlCarousel({
      loop:true,
      margin:10,
      nav:false,
      dots: false,
      autoplay: true,
      responsiveClass:true,
      autoplayTimeout: 3000,
      responsive:{
          0:{
              items:2
          },
          360:{
            items:3
          },
          420:{
              items:4
          },
          768:{
              items:6
          },
          1200:{
              items:5
        }
      }
  })
});

(function($, root, undefined) {
   $(function() {
      $(".animsition").animsition({
         inClass: "fade-in",
         outClass: "fade-out-",
         inDuration: 750,
         outDuration: 800,
         linkElement: ".blash",
         loading: true,
         loadingParentElement: "body",
         loadingClass: "animsition-loading",
         loadingInner: "",
         timeout: true,
         timeoutCountdown: 5000,
         onLoadEvent: true,
         browser: ["animation-duration", "-webkit-animation-duration"],
         overlay: false,
         overlayClass: "animsition-overlay-slide",
         overlayParentElement: "body",
         transition: function(url) {
            window.location.href = url;
         }
      });

      $(".nav__main").mmenu(
         {
            extensions: [
               "pagedim-black",
               "position-front",
               "position-right",
               "border-none"
            ],
            navbars		: {
                content : [ "searchfield", "close", ".nav-social" ],
                height 	: 2
            }
         },        
         {
            // configuration
            offCanvas: {
               pageSelector: ".animsition"
            }
         }
      );
      var API = $(".nav__main").data("mmenu");
      $(".header__main__burger").click(function() {
         API.open();
         $("#mm-1").addClass("force-opacity");
      });

      $(".latest-posts-slider").owlCarousel({
         items: 4,
         slideBy: 1,
         margin: 30,
         mouseDrag: false,
         nav: true,
         navContainer: ".latest-posts-slider-nav",
         navText: [
            '<i class="fa fa-chevron-left" aria-hidden="true"></i>',
            '<i class="fa fa-chevron-right" aria-hidden="true"></i>'
         ],
         navSpeed: 500,
         dots: false,
         responsive: {
            0: {
               items: 1
            },
            992: {
               items: 4
            }
         }
      });

      $(".image-gallery-slider").owlCarousel({
         items: 5,
         slideBy: 1,
         margin: 30,
         mouseDrag: true,
         nav: true,
         navContainer: ".image-gallery-slider-nav",
         navText: [
            '<i class="fa fa-chevron-left" aria-hidden="true"></i>',
            '<i class="fa fa-chevron-right" aria-hidden="true"></i>'
         ],
         navSpeed: 500,
         dots: false,
         responsive: {
            0: {
               items: 1
            },
            992: {
               items: 5
            }
         }
      });

      $(".logo-carousel").owlCarousel({
         items: 5,
         slideBy: 1,
         nav: false,
         dots: false,
         responsive: {
            0: {
               items: 1
            },
            768: {
               items: 5
            }
         }
      });

    $('.booking-form-inner__title').click(function() {
        $(this).find('i').toggleClass('fa-angle-double-up fa-angle-double-down');
        $(this).siblings('.booking-form-inner__form').slideToggle();
        $(this).find('.text').text(function(_, text) {
            return text === 'Book your stay' ? 'Close' : 'Book your stay';
        })
    });



    

  

    function tableResize() {
        var thead = $('thead');
        var pageContainer = $('#page__section');
        var tableInstruction = $('.responsive-table-instruction');

        pageContainer.find(thead).each(function(){
            if ( $(this).width() > pageContainer.width()) {
                $(this).closest('table').css('overflow-x', 'auto');
                $(this).closest('table').css('margin-bottom', '5px' ,'!important');
                $(this).closest('table').next($('.responsive-table-instruction')).show();
            } else {
                $(this).closest('table').next($('.responsive-table-instruction')).hide();
                $(this).closest('table').css('margin-bottom', '25px' ,'!important');
            }
        });
    }
    tableResize();
    $(window).resize(tableResize);


    var intro = document.querySelector('.banner');
    var introPlayer = document.querySelector('.banner__video');

    var iOS = /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
    intro.style.backgroundImage = 'url("' + introPlayer.poster + '")';
    introPlayer.style.display = 'none';
    }


    // function scroll_if_anchor(href) {
    //     href = typeof(href) == "string" ? href : $(this).attr("href");
        
    //     // You could easily calculate this dynamically if you prefer
    //     var fromTop = -40;
        
    //     // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
    //     // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
    //     if(href.indexOf("#") == 0) {
    //         var $target = $(href);
            
    //         // Older browser without pushState might flicker here, as they momentarily
    //         // jump to the wrong position (IE < 10)
    //         if($target.length) {
    //             $('html, body').animate({ scrollTop: $target.offset().top - fromTop });
    //             if(history && "pushState" in history) {
    //                 history.pushState({}, document.title, window.location.pathname + href);
    //                 return false;
    //             }
    //         }
    //     }
    // }    
    
    // // When our page loads, check to see if it contains and anchor
    // scroll_if_anchor(window.location.hash);
    
    // // Intercept all anchor clicks

    // $("#body").on("click", "a", scroll_if_anchor);




      var wow = new WOW({
         boxClass: "wow", // animated element css class (default is wow)
         animateClass: "animated", // animation css class (default is animated)
         offset: 0, // distance to the element when triggering the animation (default is 0)
         mobile: false, // trigger animations on mobile devices (default is true)
         live: true, // act on asynchronously loaded content (default is true)
         callback: function(box) {},
         scrollContainer: null // optional scroll container selector, otherwise use window
      });
      wow.init();
   });
})(jQuery, this);

if (!Modernizr.objectfit) {
   $(".hero__slider__item, .imagelinks").each(function() {
      var $container = $(this),
         imgUrl = $container.find("img").prop("src");
      if (imgUrl) {
         $container
            .css("backgroundImage", "url(" + imgUrl + ")")
            .addClass("object-fit");
      }
   });
}